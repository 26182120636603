import React, { useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";

import ScrollHandler from "./scrollHandler";
import Chart from "./chart";

function CharitableContributions(props) {
  // if (props.location.pathname === "/charitable-contributions") {
  //   document.body.classList.add("inner-header");
  // }
  const charitableContributions = useRef();
  const Location = useLocation();
  useEffect(() => {
    // if (Location.pathname === "/charitable-contributions" && charitableContributions.current) {
    //   charitableContributions.current.scrollIntoView();
    // }

    // if (props.location.pathname === "/charitable-contributions/") {
    //   document.body.classList.add("inner-header");
    // }

    setTimeout(() => {
      window.scrollTo(0, window.scrollY - 70);
    }, );
  }, [Location]);

  const charityData = require('../data/charity.json');
  const otherCharities = require('../data/otherCharity.json')

  return (

    <div className="charityble-contribution-wrapper">
      <div className="content-wrapper">
      <div className="company-values">
                <div className="values-title">
                    <h2>Charitable Contributions</h2>
                  </div>
                  <div className="value-text">
                    <p >
                    Our team is committed to giving back to local charities to support a
          variety of communities. From building houses for low-income families
          to researching rare pediatric cancers, we each chose causes close to
          our hearts to fight for. Here are the organizations our team members
          donated to, with matched donations from PCG, in 2022.
                  </p>
                  </div>
                </div>
      <div className="chart-container">
        <div className="fosus-sec-wapper">
          <Chart />
        </div>
      </div>
      <div className="mobile-content">{
          charityData.map((data,i)=>{
            return(
              <div key={i}>
                <a href={data.webLink}  target="_blank" rel="noreferrer" >
                <div className="charity-card">
                  <div className="image-block">
                    <img src={data.logo} alt={data.title}/>
                  </div>
                  <div className="content-block">
                    <p>{data.description}</p>
                  </div>
                </div>
                </a>
              </div>
            )
          })
        }</div>

        
      </div>
      <div className="other-charity">
        <div className="title">
          <h3>Historically we’ve also contributed to;</h3>
        </div>
        <div className="box-container">
        {
          otherCharities.map((data,i)=>{
            return(
              <div className="data-card" key={i}>
                {data.name}
                </div>
            )
          })
        }
        </div>
     
      </div>
     
    </div>
  );
}

export default CharitableContributions;
